<div class="relative p-24 rounded">
  <div class="pt-10 mx-auto text-center max-w-350">
    <h3 class="font-bold text-18 phone:text-24 text-darkerGray">
      Upload time data from CSV?
    </h3>
    <label>Below are all the records that we're attempting to import, please check their validity</label>
    <div class="pt-10 pb-10">
      <mat-accordion>
        @for (lines of bulkImportResults; let i = $index; track 'result-' + i) {
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>Line {{i+1}}</mat-panel-title>
              <mat-panel-description>
                @if (!lines.valid) {
                  <span>Errors processing this line</span>
                  <mat-icon color="warn">error</mat-icon>
                } @else {
                  <span>Successfully found matches</span>
                  <mat-icon color="primary">done</mat-icon>
                }
              </mat-panel-description>
            </mat-expansion-panel-header>
            @for (error of lines.errors; track 'error' + $index) {
              <p>
                {{error[0]}} <span>{{error[1]}}</span>
              </p>
            }
            @if (lines.valid) {
              <p class="text-13 phone:text-15">This line is able to be uploaded</p>
              <div class="grid grid-cols-3 gap-2 text-left text-12 phone:text-14 text-darkerGray">
                <div class="col-span-1">Client Name: </div>
                <div class="col-span-2">{{lines.client.clientName}}</div>
                <div class="col-span-1">Project Name: </div>
                <div class="col-span-2">{{lines.project.projectName}}</div>
                <div class="col-span-1">User: </div>
                <div class="col-span-2">{{lines.user.firstName}} {{lines.user.lastName}}</div>
                <div class="col-span-1">Selected Date: </div>
                <div class="col-span-2">{{lines.startDate}}</div>
                <div class="col-span-1">Hours: </div>
                <div class="col-span-2">{{lines.hours}}</div>
              </div>
            }
          </mat-expansion-panel>
        }
      </mat-accordion>
    </div>
    <cartwheel-button
      statusButton
      label="Add Entries"
      color="secondary"
      [status]="!(allValid |async) ? loadingStatus.Disabled : (loading$ | async)"
      (onClick)="onSubmit()"
    />
  </div>
  <cartwheel-icon-button
    class="absolute right-10 top-10"
    matIconName="clear"
    color="darkGray"
    (onClick)="closeDialog()"
  />
</div>