import { UUID } from 'angular2-uuid';
import { Dayjs } from 'dayjs';

// Overview
export class OverviewTable {
  tableData?: OverviewReport[];
  numberOfPages?: any;
  activePage?: number;
  empty: boolean;
}

export class OverviewReport {
  clientID: string;
  clientName: string;
  status: number;
  startDate: Date;
  hasDetailsToShow: boolean;
  entries: OverviewEntry[];
  timesheetID: UUID;
  name: string;
}

export class OverviewEntry {
  date: Dayjs | string | Date;
  elapsedMinutesSubtotal: number;
  displayElapsedSubtotal: string;
}

// Overview data transformation
export class ElapsedMinutesObj {
  [date: string]: ElapsedMinutesItem;
  Total: ElapsedMinutesItem;
}

export class ElapsedMinutesItem {
  displayElapsedSubtotal: string;
  elapsedMinutesSubtotal: number;
  detailed: OverviewDetail[];
}

export class OverviewDetail {
  timeEntryId: string;
  date: Dayjs;
  description: string;
  displayElapsed: string;
  elapsedMinutes: number;
  endTime: Date;
  startTime: Date;
  projectName: string;
}

// Mobile Detailed View
export class MobileDetailedView {
  clientID: string;
  clientName: string;
  status: number;
  entries: DetailsPerDate[];
}

export class DetailsPerDate {
  date: Dayjs;
  details: OverviewDetail[];
}

// Desktop Detailed View
export class DesktopDetailedViewTable {
  tableData?: DetailedViewReport[];
  numberOfPages?: any;
  activePage?: number;
}

export class DetailedViewReport {
  clientID: string;
  clientName: string;
  startDate: Date;
  status: number;
  rows: DetailedViewEntry[];
  name: string;
}

export class DetailedViewEntry {
  date: string;
  details: { timeEntryId: string }[];
}

// MISC
export class ValidationState {
  clientId: string;
  formFieldsAreValid: boolean;
}
