import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import dayjsUtc from 'dayjs/plugin/utc';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CartwheelIconButtonComponent } from '@cartwheel/web-components';
import { Project } from '../../../models/project';
import { DetailsPerDate, MobileDetailedView, OverviewDetail } from '../../../models/report';
import roundDuration from '../../../../business_logic/rounding';
import { Client } from 'app/models/Client';
import { TimeEntry } from 'app/models/timeentry';
import { ApprovalStatus } from 'app/shared/enums';
import { getStatus } from 'app/shared/helpers';
import { State, getProjectsList } from 'app/redux/reducers';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { formatDuration } from 'app/clients/client-shared-functions';
import { PipesModule } from "../../../pipes/pipes.module";

// dayjs plugins
dayjs.extend(dayjsDuration);
dayjs.extend(dayjsUtc);

@Component({
  standalone: true,
  selector: 'app-report-additional-details',
  templateUrl: './report-additional-details.component.html',
  styleUrls: ['./report-additional-details.component.scss'],
  imports: [PipesModule, CartwheelIconButtonComponent]
})
export class ReportAdditionalDetailsComponent {
  public detailedViewReport: MobileDetailedView;
  public statusType = ApprovalStatus;
  public statusTypeValues: Record<ApprovalStatus, { 
    status: string;
    label: string 
  }> = {
    [ApprovalStatus.Unsent]: { status: 'darkGray', label: 'Not sent' },
    [ApprovalStatus.Pending]: { status: 'darkGray', label: 'Pending' },
    [ApprovalStatus.Approved]: { status: 'primary', label: 'Approved' },
    [ApprovalStatus.Denied]: { status: 'danger', label: 'Rejected' },
    [ApprovalStatus.Invalid]: { status: 'danger', label: 'Invalid' },
  };

  constructor(
    private dialog: MatDialogRef<ReportAdditionalDetailsComponent>,
    private store: Store<State>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      groupedTimeEntries: [Client, TimeEntry[]];
    }
  ) {
    this.formatMobileDetailedViewData(data.groupedTimeEntries);
  }

  private formatMobileDetailedViewData([client, entries]: [Client, TimeEntry[]]) {
    const report = new MobileDetailedView();
    let entry = new DetailsPerDate();
    let detail: OverviewDetail;
    let date: dayjs.Dayjs;
    this.store.select(getProjectsList).pipe(take(1))
      .subscribe(projects => {
        report.status = getStatus(entries);
        report.clientID = client.clientID;
        report.clientName = client.clientName;
        for (const e of entries) {
          [date, detail] = this.createDetail(e, client, projects.find(p => p.projectId === e.projectId));
          if (!report.entries) {
            report.entries = [];
          }
          const whichEntryHasDate = this.whichEntryHasDate(date, report.entries);
          if (whichEntryHasDate === -1) {
            // create a new entry and add this detail to it
            entry = new DetailsPerDate();
            entry.date = date;
            entry.details = [];
            entry.details.push(detail);
            report.entries.push(entry);
          } else {
            // push detail to that entry
            report.entries[whichEntryHasDate].details.push(detail);
          }
        }
        this.detailedViewReport = this.sortEntriesByDate(report);
      });
  }

  private createDetail(entry: TimeEntry, client: Client, project: Project): [dayjs.Dayjs, OverviewDetail] {
    let detail: OverviewDetail;
    let date: dayjs.Dayjs;
    date = dayjs(entry.startTime);
    const startTimestamp = dayjs(entry.startTime);
    const endTimestamp = dayjs(entry.endTime || new Date());
    const timeRounding = client.timeRounding;
    let roundedTime;
    roundedTime = roundDuration(startTimestamp, endTimestamp, timeRounding);
    roundedTime = roundedTime ? roundedTime.asMinutes() : 0;
    const displayElapsed = formatDuration(dayjs.duration(roundedTime, 'minutes'));
    detail = {
      timeEntryId: entry.timeEntryId,
      date,
      description: entry.description,
      displayElapsed,
      elapsedMinutes: roundedTime,
      endTime: entry.startTime,
      startTime: entry.startTime,
      projectName: project.projectName
    };
    return [date, detail];
  }

  private whichEntryHasDate(date: dayjs.Dayjs, entries: DetailsPerDate[]): number {
    for (let i = 0; i < entries.length; i += 1) {
      if (dayjs.utc(entries[i].date).startOf('day') === date) {
        return i;
      }
    }
    return -1;
  }

  private getProjectName(id: string, projects: Project[]): string {
    if (!projects) {
      return;
    }
    return projects.find(s => s.projectId === id)?.projectName;
  }

  private sortEntriesByDate(report: MobileDetailedView): MobileDetailedView {
    report.entries.sort((a, b) => {
      if(a.date > b.date){
        return 1;
      } else if (a.date < b.date) {
        return -1;
      } else {
        return 0;
      }
    });
    return report;
  }

  public closeDialog() {
    this.detailedViewReport = new MobileDetailedView();
    this.dialog.close();
  }
}
