import { Project } from './project';
import { Client } from './Client';
import { CompanyUser } from './CompanyUser';

export class BulkTeamTimeUpload {
  row: string[];
  valid: Boolean;
  errors: [string, string][] = new Array<[string, string]>();
  project: Project;
  client: Client;
  user: CompanyUser;
  startDate: Date;
  hours: number;

  constructor(data?: string[]) {
    if (data) {
      this.row = data;
    }
  }
}
