import dayjs from 'dayjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TimeEntry } from '../models/timeentry';
import { environment } from '../../environments/environment';
import { Observable, Subscriber } from 'rxjs';
import { AddTimeComponent } from '../shared/dialogs/add-time/add-time.component';
import { ReportAdditionalDetailsComponent } from '../shared/dialogs/report-additional-details/report-additional-details.component';
import { InitiateClientApproval } from '../models/approver';
import { Client } from 'app/models/Client';
import { ReportRequest } from 'app/models/ReportRequest';
import { AddBulkTimeComponent } from 'app/shared/dialogs/add-bulk-time/add-bulk-time.component';
import { BulkTeamTimeUpload } from 'app/models/bulkteamtimeupload';
import { Store } from '@ngrx/store';
import { State, getDashboardRole } from 'app/redux/reducers';
import { take, switchMap } from 'rxjs/operators';

@Injectable()
export class ReportService {
  private apiUrl = `${environment.server}/Timesheet`;
  private addTimeDiagRef: MatDialogRef<AddTimeComponent>;
  private addBulkTeamEntries: MatDialogRef<AddBulkTimeComponent>;
  private showReportDetailedViewDialogRef: MatDialogRef<ReportAdditionalDetailsComponent>;

  constructor(private http: HttpClient, private dialogService: MatDialog, private store: Store<State>) {
  }

  public showAddTimeDialog(entry: TimeEntry) {
    return new Observable(ob => {
      this.addTimeDiagRef = this.dialogService.open(AddTimeComponent, {
        panelClass: 'cartwheel-base-dialog',
        data: entry
      });
      ob.next('');
    });
  }

  public closeAddTimeDialog() {
    return new Observable(ob => {
      if (this.addTimeDiagRef) {
        this.addTimeDiagRef.close();
      }
      ob.next('');
    });
  }

  public showDialog(groupedTimeEntries: [Client, TimeEntry[]]): void {
    this.showReportDetailedViewDialogRef = this.dialogService.open(ReportAdditionalDetailsComponent, {
      panelClass: 'cartwheel-base-dialog',
      data: { groupedTimeEntries }
    });
  }

  public initiateApprovalForClient(approval: InitiateClientApproval): Observable<boolean> {
    const modifiedApproval = Object.assign({}, approval, {
      initiationDate: dayjs(new Date()).format()
    });
    return this.http.post<boolean>(`${this.apiUrl}/InitiateClientApproval`, JSON.stringify(modifiedApproval));
  }



  public getDetailedExcelFile(report: ReportRequest): Observable<HttpResponse<Blob>> {
    return this.http.post(`${environment.server}/Report/GetExcelActivityReport?`, JSON.stringify(report), {
      responseType: 'blob',
      observe: 'response'
    });
  }

  // public getOverviewExcelFile(report: ReportRequest) {
  //   const req = new HttpRequest('POST',`${environment.server}/Report/GetExcelOverviewReport`, JSON.stringify(report), {
  //     responseType: 'blob',
  //     reportProgress: true
  //   })
  //   return this.http.request(req);
  // }

  public getOverviewExcelFile(report: ReportRequest): Observable<HttpResponse<Blob>> {
    return this.http.post(`${environment.server}/Report/GetExcelOverviewReport`, JSON.stringify(report), {
      responseType: 'blob',
      observe: 'response'
    });
  }

  public getOverviewPdfFile(report: ReportRequest): Observable<HttpResponse<Blob>> {
    return this.http.post(`${environment.server}/Report/GetPDFOverviewReport`, JSON.stringify(report), {
      responseType: 'blob',
      observe: 'response'
    });
  }

  public getDetailedPdfFile(report: ReportRequest): Observable<HttpResponse<Blob>> {
    return this.http.post(`${environment.server}/Report/GetPDFActivityReport`, JSON.stringify(report), {
      responseType: 'blob',
      observe: 'response'
    });
  }

  public showTimesheetHistoryDialog(entry: TimeEntry) {
    return new Observable(ob => {
      this.addTimeDiagRef = this.dialogService.open(AddTimeComponent, {
        panelClass: 'cartwheel-base-dialog',
        data: entry
      });
      ob.next('');
    });
  }

  public UploadBulkEntries(entries: BulkTeamTimeUpload[]) {
    return this.store.select(getDashboardRole).pipe(
      take(1),
      switchMap((role) => {
        return this.http.post<string>
          (`${environment.server}/Timesheet/UploadBulkEntries?companyId=${role.companyId}`,
            JSON.stringify(entries));
      }));
  }

  public closeTimesheetHistoryDialog() {
    return new Observable(ob => {
      if (this.addTimeDiagRef) {
        this.addTimeDiagRef.close();
      }
      ob.next('');
    });
  }

  public showBulkAddTimeDialog(entries: File) {
    return new Observable(ob => {
      this.addBulkTeamEntries = this.dialogService.open(AddBulkTimeComponent, {
        panelClass: 'cartwheel-base-dialog',
        data: entries,
        width: '644px',
      });
      ob.next('');
    })
  }

  public parseTimeEntryCsv(file: File): Observable<string[][]> {

    const fileReader = new FileReader();
    let parsedCsv: string[][] = null;

    fileReader.readAsText(file);
    return new Observable((ob: Subscriber<any[]>): void => {
      fileReader.onload = () => {
        parsedCsv = this.csvFileLoaded(fileReader.result);
        ob.next(parsedCsv);
      };
    });
  }

  private csvFileLoaded(fileLoadedEvent): string[][] {
    const separator = ',';
    const textFromFile = fileLoadedEvent;

    const csv = [];
    const lines = textFromFile.split('\n');

    lines.forEach(line => {
      if (line.indexOf(separator) >= 0) {
        csv.push(line.split(separator));
      }

    });
    return csv;  
  }

}

