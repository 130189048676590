<div class="relative py-15">
  <div class="mb-10 px-15">
    <span class="font-medium text-darkerGray text-20">{{ detailedViewReport.clientName }}</span>
    <cartwheel-icon-button
      matIconName="clear"
      color="darkGray"
      class="close-btn"
      (onClick)="closeDialog()"
    />
    <div class="flex -mt-5">
      <span [class]="statusTypeValues[detailedViewReport.status]?.status" [class.status-badge]="true">{{
        statusTypeValues[detailedViewReport.status]?.label
      }}</span>
    </div>
  </div>

  @for (entry of detailedViewReport.entries; track 'detailed-view-report' + $index) {
    <div class="border-b-1 border-b-lighterGray px-15">
      <div class="py-10 font-bold text-14 text-darkerGray">{{ entry.date | dateFormat: 'ddd, MMM DD' }}</div>
      @for (detail of entry.details; track 'detaied-data' + $index) {
        <div class="flex justify-between gap-5 mb-8 text-14 text-darkerGray">
          <span>{{ detail.projectName }}</span>
          <span>{{ detail.displayElapsed }}</span>
        </div>
        <div class="mb-10 text-10 text-darkGray">{{ detail.description }}</div>
      }
    </div>
  }
</div>
