<div class="dialog-body add-time-container">
  <div class="dialog-header">
    <span class="dialog-header-text">Add Time</span>
    <cartwheel-icon-button matIconName="clear" class="dialog-close-btn" (onClick)="closeDialog()" />
  </div>

  <div class="dialog-content px-15 tablet:px-75">
    <form class="grid grid-cols-1 gap-20 py-28" [formGroup]="submitTimeEntry" autocomplete="off">
      <div>
        <cartwheel-select
          label="Client Name"
          addNewType="dialog"
          [options]="clientsOptions"
          [selectFormControl]="submitTimeEntry.get('selectedClient') | formControlType"
          (onClickAddNew)="addClient()"
        />
        @if (
          submitTimeEntry.controls.selectedClient.errors &&
          submitTimeEntry.controls.selectedClient.errors.required &&
          (submitTimeEntry.controls.selectedClient.touched || submitTimeEntry.controls.selectedClient.dirty)
        ) {
          <span class="text-danger text-12">Please select a client</span>
        }
        @if (
          submitTimeEntry.controls.selectedClient.errors &&
          submitTimeEntry.controls.selectedClient.errors.clientIsPlaceholder &&
          (submitTimeEntry.controls.selectedClient.touched || submitTimeEntry.controls.selectedClient.dirty)
        ) {
          <span class="text-danger text-12">Please select a valid client</span>
        }
      </div>

      <cartwheel-select
        label="Project"
        addNewType="input"
        [options]="filteredProjectOptions"
        [disabled]="!selectedClient"
        [selectFormControl]="submitTimeEntry.get('projectName') | formControlType"
        [addNewStatus]="
          (loadingProject === successStatus.Success && successStatus.Success) ||
          (loadingProject === successStatus.Error && successStatus.Error) ||
          ((loadingProject === true || loadingProject === successStatus.IsLoading) && successStatus.IsLoading)
        "
        (saveNewElement)="addNewProject($event)"
      />

      @if ((currentRole$ | async).userRole !== UserRole.User && selectedClient) {
        <cartwheel-select
          label="User"
          [options]="assignedUsersOptions"
          [selectFormControl]="submitTimeEntry.get('userId') | formControlType"
        />
      }

      <div>
        <cartwheel-input
          label="Description"
          [inputFormControl]="submitTimeEntry.get('description') | formControlType"
        />
        @if (
          submitTimeEntry.controls.description.errors &&
          submitTimeEntry.controls.description.errors.required &&
          (submitTimeEntry.controls.description.touched || submitTimeEntry.controls.description.dirty)
        ) {
          <span class="text-danger text-12"> Please insert a description. </span>
        }
      </div>

      <div class="grid grid-cols-2 gap-20">
        <div>
          <cartwheel-datepicker
            label="Start Date"
            [dateFilter]="minMaxDateFilter"
            [dateFormControl]="submitTimeEntry.get('selectedStartDate') | formControlType"
          />

          @if (
            submitTimeEntry.controls.selectedStartDate.errors &&
            (submitTimeEntry.controls.selectedStartDate.touched || submitTimeEntry.controls.selectedStartDate.dirty)
          ) {
            @if (submitTimeEntry.controls.selectedStartDate.errors.required) {
              <span class="text-danger text-12">Please select a start date.</span>
            } @else if (submitTimeEntry.controls.selectedStartDate.errors.matDatepickerMin) {
              <span class="text-danger text-12">Entered date is too far in the past. Please select a valid date.</span>
            } @else if (submitTimeEntry.controls.selectedStartDate.errors.matDatepickerMax) {
              <span class="text-danger text-12"
                >Entered date is too far in the future. Please select a valid date.</span
              >
            } @else if (submitTimeEntry.controls.selectedStartDate.errors.matDatepickerParse) {
              <span class="text-danger text-12">Please enter a valid date.</span>
            } @else if (submitTimeEntry.controls.selectedStartDate.errors.negativeDuration) {
              <span class="text-danger text-12">Start should be before end.</span>
            }
          }
        </div>
        <div>
          <cartwheel-datepicker
            label="End Date"
            [dateFilter]="endDateMinMaxDateFilter"
            [dateFormControl]="submitTimeEntry.get('selectedEndDate') | formControlType"
          />

          @if (
            submitTimeEntry.controls.selectedEndDate.errors &&
            (submitTimeEntry.controls.selectedEndDate.touched || submitTimeEntry.controls.selectedEndDate.dirty)
          ) {
            @if (submitTimeEntry.controls.selectedEndDate.errors.required) {
              <span class="text-danger text-12">Please select an end date.</span>
            } @else if (submitTimeEntry.controls.selectedEndDate.errors.matDatepickerMin) {
              <span class="text-danger text-12"
                >Entered date is too far in the past. Please select a date after start date.</span
              >
            } @else if (submitTimeEntry.controls.selectedEndDate.errors.matDatepickerMax) {
              <span class="text-danger text-12"
                >Entered date is too far in the future. Please select a valid date.</span
              >
            } @else if (submitTimeEntry.controls.selectedEndDate.errors.matDatepickerParse) {
              <span class="text-danger text-12">Please enter a valid date. (<em>mm/dd/yyyy</em>)</span>
            } @else if (submitTimeEntry.controls.selectedEndDate.errors.negativeDuration) {
              <span class="text-danger text-12">End should be before end.</span>
            }
          }
        </div>
      </div>

      <div class="flex flex-col gap-5">
        <label class="font-medium text-middleGray text-14">Start Time</label>
        <div class="grid grid-cols-12 gap-10">
          <cartwheel-select
            class="col-span-5"
            label="Hour"
            [options]="hoursOptions"
            [selectFormControl]="submitTimeEntry.get('selectedStartHour') | formControlType"
          />
          <cartwheel-select
            class="col-span-5"
            label="Minute"
            [options]="minutesOptions"
            [selectFormControl]="submitTimeEntry.get('selectedStartMinute') | formControlType"
          />
          <cartwheel-select
            class="col-span-2"
            label="AM/PM"
            [options]="meridiemOptions"
            [selectFormControl]="submitTimeEntry.get('selectedStartMeridiam') | formControlType"
          />
        </div>
      </div>

      <div class="flex flex-col gap-5">
        <label class="font-medium text-middleGray text-14">End Time</label>
        <div class="grid grid-cols-12 gap-10">
          <cartwheel-select
            class="col-span-5"
            label="Hour"
            [options]="hoursOptions"
            [selectFormControl]="submitTimeEntry.get('selectedEndHour') | formControlType"
          />
          <cartwheel-select
            class="col-span-5"
            label="Minute"
            [options]="minutesOptions"
            [selectFormControl]="submitTimeEntry.get('selectedEndMinute') | formControlType"
          />
          <cartwheel-select
            class="col-span-2"
            label="AM/PM"
            [options]="meridiemOptions"
            [selectFormControl]="submitTimeEntry.get('selectedEndMeridiam') | formControlType"
          />
        </div>
      </div>

      @if (dateTimeValidationError) {
        <span class="text-danger text-12">{{ dateTimeValidationError }}</span>
      }

      <div class="flex flex-col gap-5">
        <label class="font-medium text-middleGray text-14">Duration</label>
        <div class="flex gap-20">
          <span class="text-14 text-middleGray">{{ durationDays }} days</span>
          <span class="text-14 text-middleGray">{{ durationHours }} hours</span>
          <span class="text-14 text-middleGray">{{ durationMinutes }} minutes</span>
        </div>
      </div>
    </form>
  </div>

  <div class="flex items-center col-span-2 gap-5 py-24 dialog-footer px-15 tablet:px-75 border-t-1 border-t-lightGray">
    <cartwheel-button
      class="[&>button]:px-40"
      label="Save"
      color="secondary"
      (onClick)="onSubmit()"
      statusButton
      [status]="
        (loadingProject === successStatus.Success && successStatus.Success) ||
        (loadingProject === successStatus.Error && successStatus.Error) ||
        ((submitTimeEntry.pristine || !submitTimeEntry.valid || dateTimeValidationError) && successStatus.Disabled) ||
        ((loadingProject === successStatus.IsLoading || loadingProject === true || submitting) &&
          successStatus.IsLoading)
      "
    />
    <cartwheel-button label="Cancel" type="text-button" color="middleGray" (onClick)="closeDialog()" />
  </div>
</div>
